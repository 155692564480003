.popover-wrapper {
	position: relative;
	display: inline-block;
}

.popover-content {
	position: absolute;
	left: 50%;
	top: 1rem;
	transform: translateX(-50%);
	background: var(--popup-background);
	backdrop-filter: blur(10px);
	border-radius: var(--border-radius);
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow for modern look */
	z-index: 1;
	width: 360px;
	border: none;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}
.popover-wrapper:hover .popover-content {
	opacity: 1;
	visibility: visible;
}

.popover-arrow {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid var(--popup-background);
	position: absolute;
	top: -10px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.popover-title {
	font-weight: bold;
	margin-bottom: 5px;
	font-size: 14px;
	color: var(--color-highlight);
}

.popover-body {
	padding: 10px;
	font-size: 14px;
	line-height: 1.5;
	text-align: justify;
	white-space: pre-wrap;
}
