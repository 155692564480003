.languages-and-busy {
	display: flex;
	justify-content: space-between;
	gap: var(--spacing-medium);
	flex-wrap: wrap;
	margin-top: var(--spacing-small);
    align-items: center;
    text-align: center;

}

.languages-and-busy section {
	flex: 1;
	min-width: 250px;
	padding: 1rem;
}



.languages-and-busy ul {
	list-style: none;
	width: 90%;
	max-width: 400px;
	padding: 0;
}

.languages-and-busy li {
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: var(--border-radius);
	background: var(--color-card-background);
}



.languages-and-busy h2 {
    display:flex;
	font-size: 1.5rem;
	margin-bottom: 1rem; /* Space below headers */
	color: var(--color-highlight);
}

.languages-and-busy p {
	margin: 0;
	font-size: 2rem;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
	.languages-and-busy {
		flex-direction: column;
	}
}
