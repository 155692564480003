.timeline-box {
	display: flex;
	flex-direction: column;
	margin: 70px 0;
	padding: 20px;
	background-color: var(--color-card-background);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
}

.line-box {
	display: flex;
	margin-bottom: 40px;
}

.text-circle {
	flex: 1;
	text-align: center;
	position: relative;
}

.text-circle:after {
	bottom: 2em; /* Adjusted for larger size */
	content: "";
	display: block;
	height: 5px; /* Increased height */
	position: absolute;
	right: 0;
	width: 50%;
	z-index: -1;
	background-color: grey;
}

.line-box h3 {
	color: var(--color-highlight);
	font-weight: bold;
	font-size: 20px; /* Increased font size */
}

.line-box h3,
.line-box p {
	font-size: 18px; /* Increased font size */
	margin-bottom: 0;
	padding: 0 10px; /* Adjusted padding */
}

.text-circle.done:after,
.text-circle.done + .text-circle:before {
	background-color: var(--color-highlight);
}

.text-circle:not(:first-child):before {
	bottom: 2em; /* Adjusted for larger size */
	content: "";
	display: block;
	height: 5px; /* Increased height */
	position: absolute;
	left: 0;
	width: 50%;
	z-index: -1;
	background-color: grey;
}

.text-circle:last-child:after {
	width: 0;
}

.circle {
	height: 100%;
}

.shape {
	height: 60px; /* Increased height */
	width: 60px; /* Increased width */
	border: 3px solid var(--color-highlight); /* Adjusted border */
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-radius: 50%;
	top: -65px;
	margin: 5px auto; /* Adjusted margin */
	background-color: #fff;
	color: var(--color-highlight);
	font-size: 30px; /* Increased icon size */
}

.subline:before {
	content: "";
	position: absolute;
	height: 20px; /* Increased size */
	width: 20px; /* Increased size */
	bottom: 14px;
	right: 15px;
	background-color: var(--color-highlight);
	border-radius: 50%;
	top: -25px;
}

.shape:hover {
	background-color: var(--color-highlight);
}
/* Responsive Design */

/* Tablets */

@media only screen and (max-width: 1200px) {
	.line-box {
		flex-direction: column; /* Stack items vertically */
		align-items: flex-start;
		position: relative; /* Ensure .shape is positioned relative to .line-box */
	}

	.text-circle {
		text-align: left;
		padding-left: 40px; /* Adjust padding to make room for the shape */
		position: relative; /* Ensure .shape is positioned relative to .text-circle */
		margin: 0;
	}

	.text-circle:after,
	.text-circle:not(:first-child):before {
		left: 20px; /* Align with circle */
		top: 50%; /* Center the line vertically */
		transform: translateY(-50%); /* Adjusted position */
		height: 100%;
		width: 5px; /* Increased width */
		background-color: var(--color-highlight);
		z-index: -1;
	}

	/* First shape (top of the first line) */
	.text-circle:first-child .shape {
		height: 40px; /* Reduced height */
		width: 40px; /* Reduced width */
		font-size: 20px; /* Reduced icon size */
		left: -37px; /* Position in the gap between lines */
		top: -75px; /* Align with the top of the text-circle */
		transform: translateY(-50%); /* Center the circle */
		position: absolute; /* Ensure it stays within the parent container */
	}

	/* Subsequent shapes (in the gap between lines) */
	.text-circle:not(:first-child) .shape {
		height: 40px; /* Reduced height */
		width: 40px; /* Reduced width */
		font-size: 20px; /* Reduced icon size */
		left: -37px; /* Position in the gap between lines */
		top: -75px; /* Center in the gap between lines */
		transform: translateY(-50%); /* Center the circle */
		position: absolute; /* Ensure it stays within the parent container */
	}
}

/* Big smartphones [426px -> 600px] */
@media only screen and (max-width: 600px) {
	.line-box {
		flex-direction: column; /* Stack items vertically */
		align-items: flex-start;
		position: relative; /* Ensure .shape is positioned relative to .line-box */
	}

	.text-circle {
		text-align: left;
		padding-left: 40px; /* Adjust padding to make room for the shape */
		position: relative; /* Ensure .shape is positioned relative to .text-circle */
		margin: 0;
	}

	.text-circle:after,
	.text-circle:not(:first-child):before {
		left: 20px; /* Align with circle */
		top: 50%; /* Center the line vertically */
		transform: translateY(-50%); /* Adjusted position */
		height: 100%;
		width: 5px; /* Increased width */
		background-color: var(--color-highlight);
		z-index: -1;
	}

	/* First shape (top of the first line) */
	.text-circle:first-child .shape {
		height: 40px; /* Reduced height */
		width: 40px; /* Reduced width */
		font-size: 20px; /* Reduced icon size */
		left: -37px; /* Position in the gap between lines */
		top: -100px; /* Align with the top of the text-circle */
		transform: translateY(-50%); /* Center the circle */
		position: absolute; /* Ensure it stays within the parent container */
	}

	/* Subsequent shapes (in the gap between lines) */
	.text-circle:not(:first-child) .shape {
		height: 40px; /* Reduced height */
		width: 40px; /* Reduced width */
		font-size: 20px; /* Reduced icon size */
		left: -37px; /* Position in the gap between lines */
		top: -85px; /* Center in the gap between lines */
		transform: translateY(-50%); /* Center the circle */
		position: absolute; /* Ensure it stays within the parent container */
	}
}

/* Medium Smartphones */
@media only screen and (max-width: 500px) {
	.line-box {
		flex-direction: column; /* Stack items vertically */
		align-items: flex-start;
		position: relative; /* Ensure .shape is positioned relative to .line-box */
	}

	.text-circle {
		text-align: left;
		padding-left: 40px; /* Adjust padding to make room for the shape */
		position: relative; /* Ensure .shape is positioned relative to .text-circle */
		margin: 0;
	}

	.text-circle:after,
	.text-circle:not(:first-child):before {
		left: 20px; /* Align with circle */
		top: 50%; /* Center the line vertically */
		transform: translateY(-50%); /* Adjusted position */
		height: 100%;
		width: 5px; /* Increased width */
		background-color: var(--color-highlight);
		z-index: -1;
	}

	/* First shape (top of the first line) */
	.text-circle:first-child .shape {
		height: 40px; /* Reduced height */
		width: 40px; /* Reduced width */
		font-size: 20px; /* Reduced icon size */
		left: -37px; /* Position in the gap between lines */
		top: -160px; /* Align with the top of the text-circle */
		transform: translateY(-50%); /* Center the circle */
		position: absolute; /* Ensure it stays within the parent container */
	}

	/* Subsequent shapes (in the gap between lines) */
	.text-circle:not(:first-child) .shape {
		height: 40px; /* Reduced height */
		width: 40px; /* Reduced width */
		font-size: 20px; /* Reduced icon size */
		left: -37px; /* Position in the gap between lines */
		top: -155px; /* Center in the gap between lines */
		transform: translateY(-50%); /* Center the circle */
		position: absolute; /* Ensure it stays within the parent container */
	}
}

/* Small smartphones [325px -> 425px] */
@media only screen and (max-width: 425px) {
	.line-box {
		flex-direction: column; /* Stack items vertically */
		align-items: flex-start;
		position: relative; /* Ensure .shape is positioned relative to .line-box */
	}

	.text-circle {
		text-align: left;
		padding-left: 40px; /* Adjust padding to make room for the shape */
		position: relative; /* Ensure .shape is positioned relative to .text-circle */
		margin: 0;
	}

	.text-circle:after,
	.text-circle:not(:first-child):before {
		left: 20px; /* Align with circle */
		top: 50%; /* Center the line vertically */
		transform: translateY(-50%); /* Adjusted position */
		height: 100%;
		width: 5px; /* Increased width */
		background-color: var(--color-highlight);
		z-index: -1;
	}

	/* First shape (top of the first line) */
	.text-circle:first-child .shape {
		height: 40px; /* Reduced height */
		width: 40px; /* Reduced width */
		font-size: 20px; /* Reduced icon size */
		left: -37px; /* Position in the gap between lines */
		top: -160px; /* Align with the top of the text-circle */
		transform: translateY(-50%); /* Center the circle */
		position: absolute; /* Ensure it stays within the parent container */
	}

	/* Subsequent shapes (in the gap between lines) */
	.text-circle:not(:first-child) .shape {
		height: 40px; /* Reduced height */
		width: 40px; /* Reduced width */
		font-size: 20px; /* Reduced icon size */
		left: -37px; /* Position in the gap between lines */
		top: -150px; /* Center in the gap between lines */
		transform: translateY(-50%); /* Center the circle */
		position: absolute; /* Ensure it stays within the parent container */
	}
}

/* Extra Small smartphones [320px and below] */
@media only screen and (max-width: 360px) {
	.line-box {
		flex-direction: column; /* Stack items vertically */
		align-items: flex-start;
		position: relative; /* Ensure .shape is positioned relative to .line-box */
	}

	.text-circle {
		text-align: left;
		padding-left: 40px; /* Adjust padding to make room for the shape */
		position: relative; /* Ensure .shape is positioned relative to .text-circle */
		margin: 0;
	}

	.text-circle:after,
	.text-circle:not(:first-child):before {
		left: 20px; /* Align with circle */
		top: 50%; /* Center the line vertically */
		transform: translateY(-50%); /* Adjusted position */
		height: 100%;
		width: 5px; /* Increased width */
		background-color: var(--color-highlight);
		z-index: -1;
	}

	/* First shape (top of the first line) */
	.text-circle:first-child .shape {
		height: 40px; /* Reduced height */
		width: 40px; /* Reduced width */
		font-size: 20px; /* Reduced icon size */
		left: -37px; /* Position in the gap between lines */
		top: -180px; /* Align with the top of the text-circle */
		transform: translateY(-50%); /* Center the circle */
		position: absolute; /* Ensure it stays within the parent container */
	}

	/* Subsequent shapes (in the gap between lines) */
	.text-circle:not(:first-child) .shape {
		height: 40px; /* Reduced height */
		width: 40px; /* Reduced width */
		font-size: 20px; /* Reduced icon size */
		left: -37px; /* Position in the gap between lines */
		top: -155px; /* Center in the gap between lines */
		transform: translateY(-50%); /* Center the circle */
		position: absolute; /* Ensure it stays within the parent container */
	}
}

@media only screen and (max-width: 1200px) and (orientation: landscape) {
}

@media only screen and (max-width: 1400px) and (orientation: landscape) {
}
