.contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100vh;
    box-sizing: border-box;
}

.contact-form-column {
    width: 100%;
    max-width: 700px;
    padding: 20px;
    margin: 0 auto;
    background: var(--color-glass-card-background);
    box-shadow: var(--box-shadow);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    border-radius: var(--border-radius);
    border: 1px solid rgb(255 255 255 / 15%);
    text-align: left;
}

h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center;
}

.status-message {
    font-size: 1.2rem;
    color: greenyellow;
    margin-bottom: 10px;
    text-align: center;
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

label {
    font-size: 1rem;
    margin-bottom: 5px;
    font-family: var(--font-family-mono);
    width: 60%;
}

input[type="text"],
input[type="email"],
textarea {
    display: flex;
    justify-content: center;
    font-family: var(--font-family-mono);
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
    background-color: var(--color-card-background);
    resize: none;
    color: white;
}

input[type="text"]::placeholder,
input[type="email"]::placeholder,
textarea::placeholder {

    color: rgba(255, 255, 255, 0.7);
}

.turnstile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
}



.send-form-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 50%;
    height: 60px;
    padding: 0 16px;
    font-size: 18px;
    line-height: 1;
    color: #fff;
    background-image: radial-gradient(100% 100% at 100% 0, #14efdc 0, #0d9165 100%) !important;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: box-shadow 0.15s, transform 0.15s;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
                rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
                rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    box-sizing: border-box;
    text-align: center;

}

.send-form-btn:focus {
    box-shadow: #26c895 0 0 0 1.5px inset,
                rgba(45, 35, 66, 0.4) 0 2px 4px,
                rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
                #26c895 0 -3px 0 inset;
}

.send-form-btn:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
                rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
                #26c895 0 -3px 0 inset;
    transform: translateY(-2px);
}

.send-form-btn:active {
    box-shadow: #26c895 0 3px 7px inset;
    transform: translateY(2px);
}


/* Responsive Adjustments */
@media (max-width: 1024px) {
    .contact-form-container {
        padding: 20px;
    }

    h2 {
        font-size: 1.5rem;
    }

    input[type="text"],
    input[type="email"],
    textarea {
        font-size: 1rem;
    }

    button {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    h2 {
        font-size: 1.3rem;
    }

    input[type="text"],
    input[type="email"],
    textarea {
        font-size: 0.9rem;
        padding: 8px;
    }

    button {
        font-size: 0.9rem;
        padding: 8px;
    }
}

@media (max-width: 480px) {
    h2 {
        font-size: 1.2rem;
    }

    input[type="text"],
    input[type="email"],
    textarea {
        font-size: 0.8rem;
        padding: 6px;
    }

    button {
        font-size: 0.8rem;
        padding: 6px;
    }

    .contact-form-container {
        padding: 15px;
    }

    .contact-form-column {
        padding: 15px;
    }
}
