.portfolio {
	width: 100%;
	height: auto;
	margin: 0;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	bottom: 100px;
}

.portfolio-category {
	padding-top: 10vh;
	margin-left: 4rem;
	font-size: 5rem;
	color: var(--color-secondary-text);
	text-align: left;
}

.project-wrapper {
	opacity: 1; /* Ensure all projects are fully visible */
	transform: translateY(0);
	transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Responsive Styles */

/* Screens up to 1200px */
@media (max-width: 1200px) {
	.portfolio-category {
		font-size: 3.5rem;
		padding-top: 14vh;
	}

	.project-wrapper {
		padding: 0 2rem;
	}
}

/* Screens up to 768px */
@media (max-width: 768px) {
	.portfolio-category {
		font-size: 3rem;
		margin-left: 1rem;
		padding-top: 12vh;
		text-align: center;
	}

	.project-wrapper {
		height: auto;
		padding: 0 1rem;
	}
}

/* Screens up to 480px */
@media (max-width: 480px) {
	.portfolio-category {
		font-size: 2rem;
		margin-left: 1rem;
		padding-top: 15vh;
		text-align: center;
	}

	.project-wrapper {
		height: auto;
		padding: 0 0.5rem;
	}
}
