.intro {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.intro-text {
	flex: 1;
	max-width: var(--max-width-content);
	margin-right: var(--spacing-large);
	font-size: 1.3rem;
	background: var(--color-card-background);
	padding: var(--spacing-medium);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
}

.intro-text h1 {
	font-size: var(--font-size-large);
	font-weight: 600;
}

.highlight {
	color: var(--color-highlight);
}

.profile-pic {
	border-radius: 50%;
	box-shadow: var(--box-shadow);
	border: 5px solid #fff;
	width: 100%;
	max-width: 550px;
	height: auto;
	object-fit: cover;
	align-self: center;
	margin: var(--spacing-medium) auto;
}
@media only screen and (max-width: 1200px) {
	.intro {
		margin: 0;
	}

	.intro-text {
		margin: 0;
		padding: 0 var(--responsive-spacing-large) 0 var(--responsive-spacing-large);
		font-size: 1.1rem;
	}

	.intro-text h1 {
		margin-top: 1rem;
		font-size: 2.5rem;
	}
}

/* Big smartphones [426px -> 600px] */
@media only screen and (max-width: 600px) {
	.intro {
		margin: 0;
	}

	.intro-text {
		margin: 0;
		padding: 0 var(--responsive-spacing-large) 0 var(--responsive-spacing-large);
		font-size: 1.1rem;
	}

	.intro-text h1 {
		margin-top: 1rem;
		font-size: 2.5rem;
	}
}

/* Medium Smartphones */
@media only screen and (max-width: 500px) {
	.intro {
		margin: 0;
	}

	.intro-text {
		margin: 0;
		padding: 0 var(--responsive-spacing-large) 0 var(--responsive-spacing-large);
		font-size: 1.1rem;
	}

	.intro-text h1 {
		margin-top: 1rem;
		font-size: 2.5rem;
	}
}

/* Small smartphones [325px -> 425px] */
@media only screen and (max-width: 425px) {
	.intro {
		margin: 0;
	}

	.intro-text {
		margin: 0;
		padding: 0 var(--responsive-spacing-medium) 0
			var(--responsive-spacing-medium);
		font-size: 1.1rem;
	}

	.intro-text h1 {
		margin-top: 1rem;
		font-size: 2.5rem;
	}
}

/* Extra Small smartphones [320px and below] */
@media only screen and (max-width: 320px) {
	.intro {
		margin: 0;
	}

	.intro-text {
		margin: 0;
		padding: 0 var(--responsive-spacing-small) 0 var(--responsive-spacing-small);
		font-size: 1.1rem;
	}

	.intro-text h1 {
		margin-top: 1rem;
		font-size: 2.5rem;
	}
}

@media only screen and (max-width: 1200px) and (orientation: landscape) {
	.intro {
		margin: 0;
	}

	.intro-text {
		margin: 0;
		padding: 0 var(--responsive-spacing-large) 0 var(--responsive-spacing-large);
		font-size: 1.1rem;
	}

	.intro-text h1 {
		margin-top: 1rem;
		font-size: 2.5rem;
	}

	.profile-pic {
		width: 100%;
		max-width: 400px;
		height: auto;
		object-fit: cover;
		align-self: center;
		margin: var(--spacing-medium) var(--responsive-spacing-large)
			var(--spacing-medium) var(--responsive-spacing-large);
	}
}

@media only screen and (max-width: 1400px) and (orientation: landscape) {
	.intro {
		margin: 0;
	}

	.intro-text {
		margin: 0;
		padding: 0 var(--responsive-spacing-large) 0 var(--responsive-spacing-large);
		font-size: 1.1rem;
	}

	.intro-text h1 {
		margin-top: 1rem;
		font-size: 2.5rem;
	}

	.profile-pic {
		width: 100%;
		max-width: 450px;
		height: auto;
		object-fit: cover;
		align-self: center;
		margin: var(--spacing-medium) var(--responsive-spacing-large)
			var(--spacing-medium) var(--responsive-spacing-large);
	}
}
