.links {
	display: flex;
	gap: var(--spacing-small);
	margin-top: var(--spacing-small);
	font-size: 1.4rem;
}

.links a {
	color: var(--color-highlight);
	text-decoration: none;
	display: flex;
	align-items: center;
}

.links a:hover {
	text-decoration: underline;
}

.download-cv {
	display: flex;
	align-items: center;
	font-size: 1rem;
	background-color: var(--color-glass-content-background);
	padding: var(--spacing-small) var(--spacing-small);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
}

.download-cv svg {
	margin-left: var(--spacing-small);
}

.download-cv:hover {
	background-color: rgba(6, 6, 7, 0.4);
	color: var(--color-link-hover);
}

@media only screen and (max-width: 1200px) {
	.links {
		flex-direction: row;
		align-items: center;
		margin-top: var(--spacing-medium);
	}
}

/* Big smartphones [426px -> 600px] */
@media only screen and (max-width: 600px) {
	.links {
		flex-direction: row;
		align-items: center;
		margin-top: var(--spacing-medium);
	}
}

/* Medium Smartphones */
@media only screen and (max-width: 500px) {
	.links {
		flex-direction: row;
		align-items: center;
		margin-top: var(--spacing-medium);
	}
}

/* Small smartphones [325px -> 425px] */
@media only screen and (max-width: 425px) {
	.links {
		flex-direction: row;
		align-items: center;
		margin-top: var(--spacing-medium);
	}
}

/* Extra Small smartphones [320px and below] */
@media only screen and (max-width: 320px) {
	.links {
		flex-direction: row;
		align-items: center;
		margin-top: var(--spacing-medium);
	}
}

@media only screen and (max-width: 1200px) and (orientation: landscape) {
}

/* Big Tablets */

@media only screen and (max-width: 1400px) and (orientation: landscape) {
}
